export const langData = [
  {
    title: 'English',
    key: 'en',
    image: new URL('@/assets/imgs/lang/en.png', import.meta.url)
  },
  {
    title: 'Français',
    key: 'fr',
    image: new URL('@/assets/imgs/lang/fr.png', import.meta.url)
  },
  {
    title: 'Nederlands',
    key: 'nl',
    image: new URL('@/assets/imgs/lang/en.png', import.meta.url)
  },
  ]
  