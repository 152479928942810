<template>
  <div class="" style=" ">

    <!-- 客服 -->

    <div @click="clickKf" style="position: fixed; right: 3%; bottom: 30px; z-index: 999; cursor: pointer; " >
      <img src="../assets/imgs/kf.png" class="kefu" style=""  alt="">
    </div>

    <div ref="target1" style="height: 100px;"></div>

    <div class="bg-white-black h-100-80" style=" width: 100%; position: fixed; top: 0; z-index: 999; left: 0; ">
    </div>

    <div class="flex-c" style="max-width: 1200px; margin: 0 auto;">

      <div class="flex-r top-30-15"
        style="position: fixed;  display: flex;  align-items: center; height: 60px; z-index: 999; ">

        <div class="max-r-min-c" style=" align-items: center ">
          <div class="color-black-white" style="font-size: 20px; font-weight: bold; ">{{ $t('全球职业网络') }}</div>
          <div style="width: 20px; height: 10px;"></div>
          <div style="color: #999;">{{ $t('远程在家办公职位') }}</div>
        </div>

        <div class="max-hind-min-show flex-r " @click.stop="show_menu = !show_menu"
          style="color: white; position: fixed; right: 3%; top: 50px; color: #999; align-items: center; ">
          <img src="../assets/imgs/menu.png" style="width: 20px; height: 20px; object-fit: contain;" alt="">
          {{ $t('MENU') }}
        </div>

      </div>

      <!-- menu -->
      <!-- <transition name="slide"> -->
      <div v-if="show_menu" class="flex-c" ref="menu_list"
        style="position: fixed; text-align: start; z-index: 999; top: 80px; background-color: black; color: white; left: 0; width: 100%; padding: 10px; box-sizing: border-box ">

        <div style="color: #999; padding: 10px 0; "
          @click="$refs.target1.scrollIntoView({ behavior: 'smooth' }); show_menu = false;">
          {{ $t('Home') }}
        </div>

        <div style="color: #999; padding: 10px 0; "
          @click="$refs.target3.scrollIntoView({ behavior: 'smooth' }); show_menu = false;">
          {{ $t('About') }}
        </div>

        <div style="color: #999; padding: 10px 0; "
          @click="$refs.target5.scrollIntoView({ behavior: 'smooth' }); show_menu = false;">
          {{ $t('Applicants') }}
        </div>

        <div style="color: #999; padding: 10px 0; "
          @click="$refs.target6.scrollIntoView({ behavior: 'smooth' }); show_menu = false;">
          {{ $t('Benefit') }}
        </div>

        <div style="color: #999; padding: 10px 0; "
          @click="$refs.target8.scrollIntoView({ behavior: 'smooth' }); show_menu = false;">
          {{ $t('Contact') }}
        </div>

        <div style="color: #999; padding: 10px 0; " @click="show_lang = !show_lang">
          {{ $t('lang') }} ▼
        </div>

        <div v-if="show_lang" class="flex-c" style="background-color: white; border-radius: 6px; color: black; ">
          <div style="padding: 15px" @click="changePhoneLang('en')">
            English
          </div>

          <div style="padding: 15px" @click="changePhoneLang('fr')">
            Français
          </div>


          <div style="padding: 15px" @click="changePhoneLang('nl')">
            Nederlands
          </div>

        </div>

      </div>
      <!-- </transition> -->



      <div class="max-show-min-hind"
        style="position: fixed;  align-items: center; height: 60px; right: 10%; top: 30px; z-index: 999; ">

        <span class="margin-0-15" style="cursor: pointer;"
          @click="$refs.target1.scrollIntoView({ behavior: 'smooth' })">{{ $t('Home') }}</span>

        <span class="margin-0-15" style="cursor: pointer;"
          @click="$refs.target3.scrollIntoView({ behavior: 'smooth' })">{{ $t('About') }}</span>

        <span class="margin-0-15" style="cursor: pointer;"
          @click="$refs.target5.scrollIntoView({ behavior: 'smooth' })">{{ $t('Applicants') }}</span>

        <span class="margin-0-15" style="cursor: pointer;"
          @click="$refs.target6.scrollIntoView({ behavior: 'smooth' })">{{ $t('Benefit') }}</span>

        <span class="margin-0-15" style="cursor: pointer;"
          @click="$refs.target8.scrollIntoView({ behavior: 'smooth' })">{{ $t('Contact') }}</span>

        <Dropdown></Dropdown>
      </div>


      <!-- 1 -->
      <div class="max-r-min-c" style="align-items: center;">
        <div class="flex-c" style=" width: 100%; flex: 1;  padding: 30px 0px; font-weight: bold;  ">

          <div style="font-size: 25px;">
            {{ $t('A GREAT PLACE TO START EARNING ONLINE') }}
          </div>

          <div class="font-60-40" style=" padding: 30px 0; color: #1f4b3f; ">
            {{ $t('WORK FROM HOME') }}
          </div>


          <div style="font-size: 25px;">
            {{ $t('Already 3,130+ people have started working!') }}
          </div>

        </div>

        <div class="flex-c" style=" width: 100%; flex: 1; ">


          <img src="../assets/imgs/2023102501262535922.svg" style="width: 100%; object-fit: contain;" alt="">


        </div>

      </div>

    </div>


    <!-- 2 -->

    <div style="background-color: #1f4b3f; width: 100%; ">

      <div class="max-r-min-c"
        style="max-width: 1200px; margin: 0 auto; color: white; padding: 100px 30px; align-items: flex-start; ">

        <div v-for="(item, index) in taskList" :key="index" class="flex-c"
          style="flex: 1; align-items: center; margin: 10px 20px; ">
          <img :src="item.src" style="height: 80px; width: 80px; " alt="">

          <div style="font-size: 18px; font-weight: bold; margin: 8px 0; ">{{ $t(item.title) }}</div>

          <div style="color: #ffffffb3; line-height: 180%; ">{{ $t(item.sub) }}</div>

        </div>

      </div>

    </div>

    <!-- 3 -->
    <div ref="target3" style="width: 100%; padding: 100px 0px; 
    background-size: cover;  background-repeat: no-repeat; "
      :style="{ backgroundImage: `url(${require('@/assets/imgs/bg_1.jpg')})` }">

      <div class="flex-c" style="max-width: 1200px; margin: 0 auto;">

        <div class="max-r-min-c" style="margin: 0 10px;">
          <div class="flex-c p-48-10" style="flex: 1; max-width: 540px; border-radius: 10px; font-weight: bold; background-color: white;
             text-align: start; box-sizing: border-box; ">
            <div style="color: #1f4b3f; font-size: 14px;">
              {{ $t('i3-title') }}
            </div>
            <div style="font-size: 32px; margin: 8px 0; line-height: 130%; ">
              {{ $t('i3-sub') }}
            </div>
            <div style="color: #999; line-height: 180%; ">
              {{ $t('i3-content') }}
            </div>
          </div>

          <div style="flex: 1;">

          </div>


        </div>

      </div>

    </div>


    <!-- 4 -->

    <div style="background-color: #1f4b3f; width: 100%; ">

      <div class="max-r-min-c"
        style="max-width: 1200px; margin: 0 auto; color: white; padding: 100px 30px; align-items: flex-start; ">

        <div v-for="(item, index) in data4List" :key="index" class="flex-c"
          style="flex: 1; align-items: center; margin: 10px 20px; text-align: center; width: 90%; ">

          <div style="font-size: 48px; font-weight: 500; margin: 8px 0; ">{{ item.num }}</div>

          <div style="color: #ffffffb3; font-size: 18px; font-weight: bold; ">{{ $t(item.title) }}</div>

        </div>

      </div>

    </div>

    <!-- 5 -->

    <div ref="target5" style="background-color: white; width: 100%; ">

      <div class="flex-c" style="max-width: 1200px; margin: 0 auto; padding: 100px 10px 0; ">

        <div style="font-size: 18px; color: #0000004d; font-weight: bold; ">{{ $t('i5-title') }}</div>
        <div style="font-size: 30px; font-weight: bold; line-height: 180%; ">{{ $t('i5-sub') }}</div>

        <div class="max-r-min-c" style="width: 100%; margin: 50px 0">
          <div v-for="(item, index) in data5List" :key="index" class="flex-c"
            style="flex: 1; margin: 50px 20px; width: 90%;
            align-items: flex-start; position: relative;
             border: 1px solid #ccc; box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1); text-align: start; padding: 70px 10px 80px;">

            <div style="color: #999; font-weight: bold; margin: 8px 0; line-height: 180%; ">{{ $t(item.content) }}</div>

            <div style=" font-size: 18px; margin: 20px 0 10px; ">{{ item.name }}</div>

            <div style=" font-size: 14px; font-weight: bold; color: #ccc; ">{{ $t('JOINED') }} {{ item.year }}</div>

            <img :src="item.src"
              style="height: 100px; width: 100px; object-fit: contain; position: absolute; top: -40px;" alt="">

          </div>

        </div>


      </div>
    </div>

    <!-- 6 -->

    <div ref="target6" style="background-color: #1f4b3f; width: 100%; ">

      <div class="flex-c" style="max-width: 1200px; margin: 0 auto; color: white; padding: 100px 30px; ">

        <div style="font-size: 18px; color: #ffffffe6; font-weight: bold; ">{{ $t('i6-title') }}</div>
        <div style="font-size: 30px; font-weight: 900; line-height: 200%; margin-bottom: 20px; ">{{ $t('i6-sub') }}
        </div>


        <div class="max-r-min-c" v-if="i6_index == 1">
          <div v-for="(item, index) in data6_1list" :key="index" class="flex-c"
            style="flex: 1; margin: 10px 20px; text-align: center; width: 90%; ">
            <div style="font-size: 20px; font-weight: bold; margin: 8px 0; ">{{ $t(item.title) }}</div>

            <div style="color: #ffffffb3; font-size: 14px; line-height: 180%; ">{{ $t(item.sub) }}</div>

          </div>
        </div>

        <div class="max-r-min-c" v-else>
          <div v-for="(item, index) in data6_2list" :key="index" class="flex-c"
            style="flex: 1; margin: 10px 20px; text-align: center; width: 90%; ">
            <div style="font-size: 20px; font-weight: bold; margin: 8px 0; ">{{ $t(item.title) }}</div>

            <div style="color: #ffffffb3; font-size: 14px; line-height: 180%; ">{{ $t(item.sub) }}</div>

          </div>
        </div>

        <div class="flex-r" style="align-items: center; width: 100%; justify-content: center; margin-top: 30px ">
          <img src="../assets/imgs/zuo.png" style="height: 30px; width: 30px; cursor: pointer; " @click="i6_index = 1"
            alt="">
          <div style="margin: 10px;"> 0{{ i6_index }} / 02</div>
          <img src="../assets/imgs/you.png" style="height: 30px; width: 30px; cursor: pointer; " @click="i6_index = 2"
            alt="">
        </div>

      </div>

    </div>

    <!-- 7 -->
    <div style="width: 100%; padding: 100px 0px; 
    background-size: cover;  background-repeat: no-repeat; "
      :style="{ backgroundImage: `url(${require('@/assets/imgs/c-3.jpg')})` }">

      <div class="flex-c" style="max-width: 1200px; margin: 0 auto;">

        <div class="max-r-min-c" style="margin: 0 10px;">
          <div class="flex-c p-48-10 " style="flex: 1; max-width: 540px; border-radius: 10px; font-weight: bold; background-color: white;
             text-align: start; box-sizing: border-box; ">
            <div style="color: #1f4b3f; font-size: 14px;">
              {{ $t('i7-title') }}
            </div>
            <div style="font-size: 32px; margin: 8px 0; line-height: 130%; ">
              {{ $t('i7-sub') }}
            </div>
            <!-- <div style="color: #999; line-height: 180%; ">
              {{ $t('i3-content') }}
            </div> -->

            <ul>
              <li style="font-size: 18px; color: #999; font-weight: bold; line-height: 180%; ">{{ $t('i7-1') }}</li>
              <li style="font-size: 18px; color: #999; font-weight: bold; line-height: 180%; ">{{ $t('i7-2') }}</li>
              <li style="font-size: 18px; color: #999; font-weight: bold; line-height: 180%; ">{{ $t('i7-3') }}</li>
              <li style="font-size: 18px; color: #999; font-weight: bold; line-height: 180%; ">{{ $t('i7-4') }}</li>
              <li style="font-size: 18px; color: #999; font-weight: bold; line-height: 180%; ">{{ $t('i7-5') }}</li>
            </ul>

          </div>

          <div style="flex: 1;">

          </div>

        </div>

      </div>

    </div>


    <!-- 8 -->

    <div ref="target8" style="background-color: #1f4b3f; width: 100%; ">

      <div class="flex-c" style="max-width: 533px; margin: 0 auto; color: white; padding: 100px 30px; ">

        <div style="font-size: 30px; color: white; font-weight: 500; ">{{ $t('i8-title') }}</div>
        <div style=" font-weight: bold; color: #ffffffe6; line-height: 180%; margin: 20px; ">{{ $t('i8-sub') }}
        </div>

        <img src="../assets/imgs/working-area.jpg" style="object-fit: contain;" alt="">

      </div>

    </div>

    <!-- 9 -->

    <div style="background-color: black; width: 100%; ">

      <div class="flex-c" style="max-width: 533px; margin: 0 auto; color: white; padding: 100px 30px; ">

        <div style="line-height: 180%; font-size: 18px; color: #ffffffcc; font-weight: bold; ">
          <span style="border-bottom: 1px solid white; cursor: pointer; margin: 0 5px; "> {{ $t('i9-1') }} </span> /
          <span style="border-bottom: 1px solid white; cursor: pointer; margin: 0 5px; "> {{ $t('i9-2') }} </span> /
          <span style="border-bottom: 1px solid white; cursor: pointer; margin: 0 5px; "> {{ $t('i9-3') }} </span> /
          <span style="border-bottom: 1px solid white; cursor: pointer; margin: 0 5px; "> {{ $t('i9-4') }} </span>
        </div>

        <div style="color: #ffffffb3; font-weight: bold; line-height: 180%; margin-top: 50px; ">{{ $t('i9-b') }}</div>


      </div>

    </div>



  </div>
</template>

<script>

import Dropdown from "@/components/Dropdown.vue";
import { ElNotification } from 'element-plus';

import { mapState, mapActions } from 'vuex';

export default {
  components: {
    Dropdown,
  },
  name: 'HomePage',
  data() {
    return {
      taskList: [
        {
          title: 't1-title',
          sub: 't1-sub',
          src: require('../assets/imgs/t1.jpg'),
        },
        {
          title: 't2-title',
          sub: 't2-sub',
          src: require('../assets/imgs/t2.jpg'),
        },
        {
          title: 't3-title',
          sub: 't3-sub',
          src: require('../assets/imgs/t3.jpg'),
        },
        {
          title: 't4-title',
          sub: 't4-sub',
          src: require('../assets/imgs/t4.jpg'),
        },
      ],
      data4List: [
        {
          num: '2,000+',
          title: 'i4-t1'
        },
        {
          num: '300+',
          title: 'i4-t2'
        },
        {
          num: '9,000+',
          title: 'i4-t3'
        },
        {
          num: '10,500+',
          title: 'i4-t4'
        },
      ],
      data5List: [
        {
          src: require('../assets/imgs/avatar1.jpg'),
          name: "Ryan",
          year: 2019,
          content: "i5-s1"
        },
        {
          src: require('../assets/imgs/avatar2.jpg'),
          name: "Alison",
          year: 2022,
          content: "i5-s2"
        },
        {
          src: require('../assets/imgs/avatar3.jpg'),
          name: "Rick",
          year: 2021,
          content: "i5-s3"
        },
      ],
      data6_1list: [
        {
          title: "i6-t1",
          sub: "i6-s1"
        },
        {
          title: "i6-t2",
          sub: "i6-s2"
        },
        {
          title: "i6-t3",
          sub: "i6-s3"
        },
        {
          title: "i6-t4",
          sub: "i6-s4"
        },
        {
          title: "i6-t5",
          sub: "i6-s5"
        },
      ],
      data6_2list: [
        {
          title: "i6-t5",
          sub: "i6-s5"
        },
        {
          title: "i6-t6",
          sub: "i6-s6"
        },
        {
          title: "i6-t7",
          sub: "i6-s7"
        },
        {
          title: "i6-t8",
          sub: "i6-s8"
        },
        {
          title: "i6-t9",
          sub: "i6-s9"
        },
      ],
      i6_index: 1,
      show_menu: false,
      show_lang: false,
      kf_url: "",
    }
  },
  computed: {
    ...mapState(['category_list'])
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    this.fetchData();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    ...mapActions(['fetchCate', 'postCate', 'postShopDetail']),
    clickKf() {
      window.location.href = this.kf_url; 
    },
    changePhoneLang(lang) {
      this.$i18n.locale = lang;
      this.show_menu = false;
    },
    async postCategory() {
      const data = {
        // 这里填入需要POST的数据
        pageSize: 50,
        pageNum: 1
      };
      await this.postCate(data);
    },
    // 原生请求
    async fetchData() {
      try {
        const response = await fetch('https://www.movie6678.top/api/base/get_luodi');
        if (!response.ok) {
          throw new Error('网络响应错误');
        }
        const result = await response.json();

        this.kf_url = result.q_luodi_custom;
        // console.log(result.q_luodi_custom);
        // if (result.status == 200) {
        //   console.log(result.data);
        // } else {
        //   console.log(result.status);
        // }

      } catch (error) {
        console.error('请求失败:', error);
      }
    },
    handleClickOutside() {
      if (this.$refs.menu_list && !this.$refs.menu_list.contains(event.target)) {
        this.show_menu = false;
      }
    },
    changeLang() {

      if (this.$i18n.locale == 'en') {
        this.$i18n.locale = 'cn';
      } else {
        this.$i18n.locale = 'en';
      }
      localStorage.setItem('my_lang', this.$i18n.locale);

      console.log(process.env.VUE_APP_API_URL);

    },
    showNotification() {
      // ElNotification.success({
      //   title: 'Success',
      //   message: 'This is a success message!',
      //   duration: 3000
      // });
      ElNotification.error({
        title: 'Error',
        message: 'This is an error message!',
        duration: 3000
      });
      // ElNotification.info({
      //   title: 'Info',
      //   message: 'This is an info message!',
      //   duration: 3000
      // });
      // ElNotification.warning({
      //   title: 'Warning',
      //   message: 'This is a warning message!',
      //   duration: 3000
      // });
    }
  },
}
</script>

<style scoped>
.flex-c {
  display: flex;
  flex-direction: column;
}


.flex-r {
  display: flex;
  flex-direction: row;
}

.max-r-min-c {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}


.max-show-min-hind {
  display: flex;
}

.margin-0-15 {
  margin: 0 15px;
}

.font-60-40 {
  font-size: 60px;
}

.bg-white-black {
  background-color: white;
}

.color-black-white {
  color: black;
}

.max-hind-min-show {
  display: none;
}

.top-30-15 {
  top: 30px
}

.h-100-80 {
  height: 100px;
}

.p-48-10 {
  padding: 48px;
}

.kefu {
  width: 90px;
  height: 90px;
}

/* 定义 slide 动画 */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  max-height: 0;
  opacity: 0;
}

.slide-enter-to,
.slide-leave {
  max-height: 300px;
  /* 根据实际内容调整这个值 */
  opacity: 1;
}

@media screen and (max-width: 768px) {

  .max-r-min-c {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .max-show-min-hind {
    display: none;
  }


  .font-60-40 {
    font-size: 40px;
  }


  .bg-white-black {
    background-color: black;
  }

  .color-black-white {
    color: white;
  }

  .max-hind-min-show {
    display: flex
  }

  .top-30-15 {
    top: 15px
  }

  .h-100-80 {
    height: 80px;
  }

  .p-48-10 {
  padding: 20px 10px;
}

.kefu {
  width: 60px;
  height: 60px;
}

}
</style>