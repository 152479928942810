<!-- LangList.vue -->
<template>
  <div ref="dropdown" style=" display: flex; flex-direction: column; background-color: white;  cursor: pointer;">

    <div @click="show_list = !show_list" style="display: flex; 
     align-items: center; " class="item-lang">

      <span :style="{ 'color': show_list ? '#1989fa' : '#2c3e50' }">{{ current_text }}</span>
      <img :src="require(show_list ? '@/assets/imgs/down-b.png' : '@/assets/imgs/down-n.png')" style="margin-left: 5px;"
        class="down-img" alt="">

    </div>

    <div v-if="show_list" style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); max-height: 200px;
     overflow-y: scroll; position: absolute; top: 43px;">

      <div @click="clickItem(item)" v-for="(item, index) in langData" :key="index" style="text-align: start;"
        class="item-lang item">
        {{ item.title }}
      </div>
    </div>


  </div>
</template>

<script>

import { langData } from '@/assets/data-js/lang';

export default {
  name: 'LangList',
  data() {
    return {
      current_text: '选择语言',
      show_list: false,
    }
  },
  computed: {
    langData() {
      return langData;
    },
  },
  created() {
    // this.current_text = this.$i18n.locale;
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    this.current_text = this.getTitleByKey(this.$i18n.locale);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    getTitleByKey(key) {
      const item = this.langData.find(lang => lang.key === key);
      return item ? item.title : 'Not Found';
    },
    clickItem(item) {
      this.current_text = item.title;
      this.show_list = false;

      this.$i18n.locale = item.key;
    },
    toggleList() {
      this.show_list = !this.show_list;
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.show_list = false;
      }
    }
  },
}
</script>

<style scoped>
.down-img {
  height: 11px;
  width: 11px;
  object-fit: contain;
}

.item-lang {
  /* border-bottom: 1px solid #ddd; */
  background-color: white;
  font-size: smaller;
  padding: 10px;
}

.item:hover {
  background-color: #eee;
  color: #1989fa;
}

@media screen and (max-width: 768px) {

  /* .item-lang {
    background-color: red;
    font-size: smaller;
    padding: 10px;
  } */

}
</style>