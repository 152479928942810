// index.js

import { createI18n } from 'vue-i18n'
// import { getStorage } from '@/utils/utis'

import en from './en.json';
import cn from './cn.json';
import fr from './fr.json';
import nl from './nl.json';


const lang = localStorage.getItem('my_lang') || 'en'// getBrowserLang()

const messages = {
    en: {
        ...en,
        // ...elementEnLocale
    },
    cn: {
        ...cn,
    },
    fr: {
        ...fr,
    },
    nl: {
        ...nl,
    }
}

// 创建vueI18n实例并输出，在main.js中调用
const i18n = new createI18n({
    // legacy: false,
    locale: lang,
    fallbackLocale: 'en', // 设置备用语言
    messages,
    warnHtmlMessage: false
})

export default i18n


