// src/api/api.js
import http from './http';
import { debounce } from 'lodash'; // 安装 lodash

// 示例API请求方法
export function getItems() {
    return http.get('/items');
}

export function getCate() {
    return http.get('/wapapi/Home/categorytk?pageSize=50&pageNum=1&lang=zh-tw');
}

export function postCate(data) {
    return http.post('/wapapi/Home/categorytk', data);
}

export function getItem(id) {
    return http.get(`/items/${id}`);
}

export function createItem(data) {
    return http.post('/items', data);
}

export function updateItem(id, data) {
    return http.put(`/items/${id}`, data);
}

export function deleteItem(id) {
    return http.delete(`/items/${id}`);
}

export function postShopDetail(data) {
    return http.post(`/shopapi/ShopDetail/getShopDetailList`, data);
}

const fetchShopdetail = debounce((data) => {
    return http.post(`/shopapi/ShopDetail/getShopDetailList`, data);
}, 1000);

export default {
    getItems,
    getCate,
    getItem,
    createItem,
    updateItem,
    deleteItem,
    postCate,
    postShopDetail: fetchShopdetail,
};