import { createStore } from 'vuex';
import api from '@/api/api'

const store = createStore({
  state() {
    return {
      count: 0,
      category_list: [],
      shop_detail: [],
    };
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    setCate(state, data) {
      state.category_list = data;
    },
    setShopDetail(state, data) {
      state.shop_detail = data;
    }
  },
  actions: {
    increment({ commit }) {
      commit('increment');
    },
    async fetchCate({ commit }) {
      try {
        const res = await api.getCate();
        commit('setCate', res.data);
      } catch (error) {
        console.error('请求失败:', error);
      }
    },
    async postCate({ commit }, data) {
      try {
        const res = await api.postCate(data);
        commit('setCate', res.data);
      } catch (error) {
        console.error('请求失败:', error);
      }
    },
    async postShopDetail({ commit }, data) {
      try {
        const res = await api.postShopDetail(data);
        commit('setShopDetail', res.data);
      } catch (error) {
        console.error('请求失败:', error);
      }
    },
  },
  getters: {
    count(state) {
      return state.count;
    }
  }
});

export default store;
