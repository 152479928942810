// src/api/http.js
import axios from 'axios';
import { ElNotification } from 'element-plus';

// 假设你有获取token和语言设置的方法
function getToken() {
  // 这里返回你的token，可以从localStorage, Vuex, 或其他地方获取
  return localStorage.getItem('token') || '';
}

function getLang() {
  // 这里返回你的语言设置，可以从localStorage, Vuex, 或其他地方获取
  return localStorage.getItem('lang') || 'en'; // 默认值为'en'
}

// 创建axios实例
const service = axios.create({
//   baseURL: 'https://api.example.com', // 基础URL
  baseURL: process.env.VUE_APP_API_URL, // 基础URL
  timeout: 5000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么，比如添加token
    // config.headers['Authorization'] = 'Bearer ' + token;

    // 在发送请求之前做些什么，比如添加 token 和 lang
    const token = getToken();
    const lang = getLang();

    // 拼接 token 和 lang 到 URL 中
    if (token) {
      config.url += (config.url.includes('?') ? '&' : '?') + 'token=' + token;
    }
    if (lang) {
      config.url += (config.url.includes('?') ? '&' : '?') + 'lang=' + lang;
    }

    return config;
  },
  error => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 对响应数据做些什么
    console.log(response.data);

    const res = response.data;

    // 显示弹窗
    if (res.status == 400) {
      ElNotification.error({
        // title: 'Error',
        message: res.mess,
        duration: 3000
      });
    }

    return res;
  },
  error => {
    // 处理响应错误
    return Promise.reject(error);
  }
);

export default service;
